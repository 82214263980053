@import url('https://fonts.googleapis.com/css2?family=Benne&display=swap');

// Gotham
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham/GothamPro-Medium/GothamPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Gotham/GothamPro-Medium/GothamPro-Medium.otf")
      format("opentype"),
    url("../fonts/Gotham/GothamPro-Medium/GothamPro-Medium.woff") format("woff"),
    url("../fonts/Gotham/GothamPro-Medium/GothamPro-Medium.ttf")
      format("truetype"),
    url("../fonts/Gotham/GothamPro-Medium/GothamPro-Medium.svg#GothamPro-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham/GothamPro-MediumItalic/GothamPro-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Gotham/GothamPro-MediumItalic/GothamPro-MediumItalic.otf")
      format("opentype"),
    url("../fonts/Gotham/GothamPro-MediumItalic/GothamPro-MediumItalic.woff")
      format("woff"),
    url("../fonts/Gotham/GothamPro-MediumItalic/GothamPro-MediumItalic.ttf")
      format("truetype"),
    url("../fonts/Gotham/GothamPro-MediumItalic/GothamPro-MediumItalic.svg#GothamPro-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: auto;
}

// Izhitsa
@font-face {
  font-family: "Izhitsa";
  src: url("../fonts/Izhitsa/IzhitsaRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// Segoe UI
@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/SegoeUI/SegoeUI/SegoeUI.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUI/SegoeUI.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUI/SegoeUI.ttf") format("truetype"),
    url("../fonts/SegoeUI/SegoeUI/SegoeUI.svg#SegoeUI") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/SegoeUI/SegoeUI-Italic/SegoeUI-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUI-Italic/SegoeUI-Italic.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUI-Italic/SegoeUI-Italic.ttf") format("truetype"),
    url("../fonts/SegoeUI/SegoeUI-Italic/SegoeUI-Italic.svg#SegoeUI-Italic")
      format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/SegoeUI/SegoeUI-SemiBold/SegoeUI-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUI-SemiBold/SegoeUI-SemiBold.woff")
      format("woff"),
    url("../fonts/SegoeUI/SegoeUI-SemiBold/SegoeUI-SemiBold.ttf")
      format("truetype"),
    url("../fonts/SegoeUI/SegoeUI-SemiBold/SegoeUI-SemiBold.svg#SegoeUI-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}
