::-webkit-scrollbar {
  width: var(--scrollbar-side);
  height: var(--scrollbar-side);
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb {
  border-radius: calc(var(--scrollbar-side) / 2);
  background: var(--scrollbar-color);
}

* {
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
  scrollbar-width: thin;

  &::selection {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  @include clr;

  font-family: $segoe;
  font-size: 20px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-color: $primary;
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $izhitsa;
}

body {
  &.body--modal {
    overflow: hidden;
  }
}
