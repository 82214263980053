._top {
  position: relative;

  &__fire {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 170px;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        #020001 100%
      );
    }

    video {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      animation: fade 1s 1s both ease-in-out;

      @include touch {
        display: none;
      }
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;

      @include desktop {
        display: none;
      }
    }
  }

  &__content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: translateZ(0);
  }

  &__title {
    @include clr;

    font-family: $gotham;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    cursor: default;
  }

  &__logo {
    margin-top: -13px;

    @include mobile {
      margin-top: 0;
    }

    img {
      max-width: 100%;
    }
  }

  &__nav {
    @include clr;

    margin-top: 20px;

    &-item {
      &:not(:last-child) {
        margin-bottom: 0.5em;
      }

      a {
        outline: none;
        text-decoration: none;
        color: $text-color;
        font-size: 24px;
        font-family: $izhitsa;
        border-bottom: 1px solid transparent;
        transition: border $speed ease-in-out;

        &:hover {
          border-bottom-color: $text-color;
        }
      }
    }
  }
}
