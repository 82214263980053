@mixin default-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

._content {
  padding: 113px 0 30px;

  @include touch {
    padding: 30px 0;
  }

  &__title {
    @include clr;

    text-align: center;
    font-size: 28px;
  }

  &__desc {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-top: 17px;
    color: rgba(255, 255, 255, 0.6);
  }

  &__box {
    margin-top: 73px;

    h3 {
      font-size: 34px;
      margin-block-start: 2em;
      margin-block-end: 1em;
    }

    h4 {
      font-size: 20px;
      margin-block-start: 2.2em;
      margin-block-end: 1.1em;
    }

    p {
      @include default-text;
    }

    ul,
    ol {
      li {
        @include default-text;
      }
    }
  }
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;

  @include touch {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    margin-top: 27px;
    margin-bottom: 62px;
  }
}

table {
  border: none;
  width: 100%;
  max-width: 100%;

  @include touch {
    min-width: 700px;
  }

  tr {
    &:first-child {
      td {
        font-family: $izhitsa;
        font-size: 18px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    td {
      border: none;
      font-size: 14px;
      padding: 20px 0;

      @include touch {
        padding: 20px 10px;
      }
    }
  }
}
