._modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: none;

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.25);
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity $speed ease-in-out;

    &.open {
      opacity: 1;
    }
  }

  &__wrap {
    width: 700px;
    max-height: 80%;
    background-color: $primary;
    color: $text-color;
    padding: 30px 20px;
    box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
    overflow-y: scroll;
    transform: scale(0.8);
    opacity: 0;
    transition: opacity $speed ease-in-out, transform $speed ease-in-out;

    @include mobile {
      width: 300px;
    }

    table {
      min-width: min-content;
    }

    &.open {
      transform: scale(1);
      opacity: 1;
    }
  }
}
