._catalog {
  padding: 32px 0 30px;
  text-align: center;

  &__subtitle {
    text-align: center;
    font-family: $benne;
    font-weight: 400;
    font-size: 24px;
  }

  @include touch {
    padding-top: 0;
  }

  &__items {
    @include clr;

    display: flex;
    margin-top: 17px;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      display: block;
    }
  }

  &__item {
    flex: 0 0 30%;
    margin: 0 1.5% 20px;

    @include mobile {
      margin-bottom: 10px;
    }

    button {
      @include clean;

      padding-bottom: 15px;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      img {
        max-width: 100%;
        max-height: 440px;
        transition: transform $speed ease-in-out;
      }

      span {
        display: block;
        font-family: $izhitsa;
        color: $text-color;
        font-size: 24px;
        margin-top: 32px;

        @include mobile {
          margin-top: 20px;
        }

        &:first-of-type {
          font-family: $benne;
        }

        &:last-child {
          margin-top: 10px;
          font-size: 20px;
        }
      }
    }
  }
}
