._header {
  position: fixed;
  top: 27px;
  left: 0;
  width: 100%;
  z-index: 20;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include touch {
      position: fixed;
      top: 0;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      height: 100vh;
      left: 100%;
      transition: left $speed ease-in-out;

      &.open {
        left: 0;
      }
    }
  }

  &__bg {
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color $speed $speed ease-in-out;

    @include desktop {
      display: none;
    }

    &.open {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }

  &__toggle {
    @include clean;

    position: absolute;
    top: 20px;
    right: calc(100% + 20px);
    width: 50px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 14px 30px rgba(0, 0, 0, 0.5);
    transition: right $speed ease-in-out;

    @include desktop {
      display: none;
    }

    &.open {
      right: 260px;

      span {
        &:nth-child(1) {
          transform: translate(0, 7px) rotate(225deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translate(0, -7px) rotate(-225deg);
        }
      }
    }

    span {
      position: absolute;
      background-color: $primary;
      width: 30px;
      height: 2px;
      left: calc(50% - 15px);
      transition: all 2 * $speed ease-in-out;

      &:nth-child(1) {
        top: calc(50% - 8px);
      }

      &:nth-child(2) {
        top: calc(50% - 1px);
      }

      &:nth-child(3) {
        top: calc(50% + 6px);
      }
    }
  }

  &__nav {
    @include touch {
      transform: translateZ(0);
      background-color: black;
      width: 260px;
      flex-grow: 1;
      padding: 30px 20px;
    }
  }

  &__list {
    @include clr;

    display: flex;
    align-items: center;

    @include touch {
      flex-direction: column;
      align-items: flex-end;
    }

    &-item {
      @include touch {
        text-align: right;
      }

      &:not(:last-child) {
        margin-right: 70px;

        @include touch {
          margin-right: 0;
        }
      }

      a {
        outline: none;
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        color: $text-color;
        border-bottom: 1px solid transparent;
        padding-bottom: 2px;
        text-transform: uppercase;
        transition: border $speed ease-in-out;

        @include touch {
          display: inline-block;
          font-size: 16px;
          padding: 10px;
        }

        &:hover {
          border-bottom-color: $text-color;
        }
      }

      &--highlight {
        a {
          font-size: 16px;
          font-family: $izhitsa;
        }
      }
    }
  }

  &__phone {
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
    text-align: right;
    color: $text-color;
    border-bottom: 1px solid transparent;
    padding-bottom: 2px;
    transform: translateY(5px);
    transition: border $speed ease-in-out;

    @include touch {
      display: inline-block;
      transform: translateY(0);
      width: 260px;
      background-color: black;
      padding: 30px 20px;
    }

    &:hover {
      border-bottom-color: $text-color;
    }
  }
}
