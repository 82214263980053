.container {
  margin: auto;

  @include mobile {
    width: 100%;
    padding: 0 15px;
  }

  @include tablet {
    width: $container-tablet;
  }

  @include desktop {
    width: $container-desktop;
  }

  // @include widescreen {
  //   width: $container-widescreen;
  // }
}

.grid {
  display: grid;
  grid-gap: 0 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(12, 1fr);
}

@for $i from 1 through 12 {
  .col-#{$i} {
    -ms-grid-column-span: $i;
    grid-column: span $i;
  }
}

@for $i from 1 through 12 {
  @include mobile {
    .col-m-#{$i} {
      -ms-grid-column-span: $i;
      grid-column: span $i;
    }
  }
}

@for $i from 1 through 12 {
  @include tablet-only {
    .col-t-#{$i} {
      -ms-grid-column-span: $i;
      grid-column: span $i;
    }
  }
}

@include mobile {
  .hide-m {
    display: none;
  }
}

@include tablet-only {
  .hide-t {
    display: none;
  }
}

@include desktop-only {
  .hide-d {
    display: none;
  }
}

@include widescreen {
  .hide-w {
    display: none;
  }
}
