._footer {
  padding-bottom: 70px;

  @include touch {
    padding-bottom: 40px;
  }

  &__title {
    @include clr;

    font-size: 30px;
  }

  &__body {
    margin-top: 24px;
  }

  &__item {
    a {
      text-decoration: none;
      display: inline-block;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.8);
      border-bottom: 1px solid transparent;
      transition: border $speed ease-in-out;

      &:hover {
        border-bottom-color: rgba(255, 255, 255, 0.8);
      }

      @include touch {
        margin-bottom: 10px;
      }

      &:not(:last-child) {
        a {
          margin-bottom: 4px;
        }
      }
    }
  }
}
