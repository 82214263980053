@mixin clean() {
  border: none;
  box-shadow: none;
  background: none;
  outline: none;
  border-radius: 0;
}

@mixin clr {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin fromto($from, $to) {
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media (min-width: $widescreen) {
    @content;
  }
}
