._error {
  width: 100vw;
  height: 100vh;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;

  &__body {
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    padding: 1em 1.5em;
    display: flex;
    align-items: center;

    animation-name: bounceIn;
    animation-delay: $speed * 2;
    animation-duration: $speed * 4;
    animation-fill-mode: both;
  }

  &__img {
    flex: 0 0 80px;

    svg {
      path {
        fill: $primary;
      }
    }
  }

  &__info {
    flex: 1 1 auto;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
  }

  &__type {
    @include clr;

    color: $primary;
    font-size: 1.6rem;
    line-height: 1.8em;
    font-weight: 700;
    cursor: default;
    text-transform: uppercase;
  }

  &__message {
    @include clr;

    color: $primary;
    font-size: 1.2rem;
    line-height: 1em;
    font-weight: 700;
    cursor: default;
  }

  &__home {
    color: $primary;
    font-size: 14px;
    margin-top: 15px;
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
