._description {
  padding: 140px 0 30px;
  text-align: center;

  @include touch {
    padding: 60px 0 30px;
  }

  &__items {
    @include clr;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 73px;

    @include touch {
      margin-top: 40px;
    }
  }

  &__item {
    flex: 0 0 33%;
    margin-bottom: 55px;
    font-size: 20px;
    padding: 0 15px;
    font-weight: 700;
    color: #ea9d4f;

    @include mobile {
      flex: 0 0 100%;
      margin-bottom: 30px;
    }
  }
}
