:root {
  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(128, 128, 128, 0.5);
  --scrollbar-side: 6px;
}

$primary: black;
$text-color: white;

$segoe: "SegoeUI", sans-serif;
$izhitsa: "Izhitsa", sans-serif;
$gotham: "Gotham", sans-serif;
$benne: 'Benne', sans-serif;

$tablet: 768px + 1px;
$desktop: 1200px + 1px;
$widescreen: 1920px;

$container-widescreen: 1300px;
$container-desktop: 1180px;
$container-tablet: 720px;

$speed: 0.25s;
